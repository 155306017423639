<template>
  <div class="login-container">
    <div class="hd">
      <van-image round width="3rem" height="3rem" :src="appInfo.account.logo" />
      <h1 v-text="appInfo.account.name" />
    </div>
    <div class="bd">
      <div style="margin: 30px 16px 0">
        <van-button :url="forward_url" round block type="info">
          微信快捷登陆
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getOauthUserInfoUrl } from '@/api/app'
import { mapState } from 'vuex'
import { Form, Button, Field, Image as VanImage } from 'vant'

export default {
  name: 'Login',
  components: {
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [VanImage.name]: VanImage
  },
  data() {
    return {
      logo: require('../../assets/logo.png'),
      forward_url: '',
      otherQuery: {},
      loading: false,
      username: '10010010011',
      password: '111111'
    }
  },
  computed: {
    ...mapState({
      inApp: state => state.app.inApp,
      appInfo: state => state.app.appInfo
    })
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    // const toast = this.$toast.loading({
    //   message: '加载中...',
    //   duration: 0,
    //   forbidClick: true
    // })
    this.$nextTick(() => {
      // 微信浏览器
      if (this.inApp === 'wechat') {
        // const redirect_uri = encodeURIComponent('xxx/redirect?redirect=' + window.location.origin + '/auth-redirect')
        // 应用是使用在这种目录结构下的 /addons/lanniu_xxx/h5/，暂时没想到其它方法来取得完整的回调地址，所以这样处理一下
        const redirect_uri = window.location.href.replace('\/login', '/auth-redirect')
        // 获取后端微信授权跳转地址
        getOauthUserInfoUrl(redirect_uri).then(({ data }) => {
          // toast.clear()
          this.forward_url = data.forward_url
        })
      }
    })
  },
  methods: {
    wechatHandleClick() {
      window.location.href = this.forward_url
    },
    onSubmit(values) {
      this.loading = true
      this.$store.dispatch('user/login', values).then(() => {
        this.$toast.success({
          message: '登陆成功',
          duration: 500,
          onClose: () => {
            // 登陆成功后跳转
            if (this.redirect) {
              this.$router.push({
                path: this.redirect,
                query: this.otherQuery
              })
            } else {
              this.$router.push({ name: 'home.index' })
            }
          }
        })
      }).finally(() => {
        this.loading = false
      })
    },
    validatorUserName(val) {
      return val === '10010010011'
    },
    validatorPassword(val) {
      return val === '111111'
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="less" scoped>
  .login-container {
    background-color: #F9FBFD;
    min-height: 100vh;
    position: relative;

    .hd {
      padding: 60px 0;
      text-align: center;
      z-index: 999;

      h1 {
        font-size: 20px;
        margin: 0;
      }
    }

    .bd {
      position: absolute;
      z-index: 999;
      width: 300px;
      margin-right: -150px;
      right: 50%;
      padding: 20px 0;
    }
  }
</style>
